import { inject, Injectable } from '@angular/core';
import { CustomerId, LocationId } from '@fmnts/api';
import { LOCAL_STORAGE, webStorageItemAccessor } from '@fmnts/common/storage';
import { hasProperty, isNotNullish } from '@fmnts/core';

interface StoredLocation {
  /** ID of the location */
  id: LocationId;
  code: string;
  name: string;
  customer: CustomerId;
  /**
   * Timestamp of the check in.
   * This value is not written by the old frontend, but only by
   * the new frontend.
   */
  timestamp?: string;
}

const hasLocationId = hasProperty('id', isNotNullish);

/**
 * Service for persisting / hydrating the location data in next.
 *
 * **NOTE**: This service persists data that is also relevant for the
 * old frontend.
 */
@Injectable({
  providedIn: 'root',
})
export class OldFrontendLocationStorageService {
  private readonly storageAccessor = webStorageItemAccessor<StoredLocation>({
    storage: inject(LOCAL_STORAGE),
    key: 'ngStorage-selectedLocation',
    defaultState: null,
  });

  /**
   * Stored location that the user selected
   */
  private get storedLocation() {
    const { value } = this.storageAccessor;

    return hasLocationId(value) ? value : null;
  }

  public get storedCheckIn() {
    const { storedLocation } = this;
    return storedLocation ? _coerceCheckIn(storedLocation) : null;
  }

  public setStoredLocation(value: StoredLocation) {
    this.storageAccessor.reset(value);
  }

  public clearStoredLocation() {
    this.storageAccessor.removePersisted();
  }
}

/**
 * Helper function to parse the stored location
 */
function _coerceCheckIn({ id, timestamp }: StoredLocation) {
  return {
    locationId: id,
    // The OF doesn't store the check in timestamp.
    // But since fundraisers should be logged out every day, we can assume
    // that the check in must have happened today.
    timestamp: timestamp ?? new Date().toISOString(),
  };
}
