import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import * as Arr from 'effect/Array';
import * as O from 'effect/Option';
import * as Predicate from 'effect/Predicate';
import { shareReplay } from 'rxjs';
import { GET_PREFERED_LOCALES } from './i18n.tokens';
import * as Locale from './locale.model';

export abstract class LocaleOptions {
  /** Default locale used by the locale service. */
  abstract readonly default: Locale.LocaleId;
  abstract readonly supported: Arr.NonEmptyReadonlyArray<Locale.LocaleId>;
}

@Injectable()
export class LocaleService {
  private readonly cfg = inject(LocaleOptions);
  /**
   * Set of supported locales, that specify which locales
   * are allowed to be set.
   */
  private readonly supported: readonly Locale.LocaleId[] = this.cfg.supported;

  /**
   * Fallback locale to use.
   *
   * @deprecated Will become private. There should be no need to access this property directly.
   * To get the current locale use {@link localeId} instead.
   */
  public readonly fallbackLocale = this.cfg.default;
  private readonly _localeId = signal(this.fallbackLocale);

  /** Current locale identifier. */
  readonly localeId = this._localeId.asReadonly();

  /**  Emits the current locale.
   *
   * @deprecated Use {@link localeId} instead.
   */
  readonly locale$ = toObservable(this.localeId).pipe(shareReplay(1));

  constructor() {
    const preferedLocales = O.fromNullable(
      inject(GET_PREFERED_LOCALES, { optional: true })?.(),
    );
    if (O.isSome(preferedLocales)) {
      this._initFromInitializer(preferedLocales.value);
    }
  }

  /**
   * Sets the current locale using a locale identifier.
   * If the locale is not supported, it will be ignored.
   *
   * @param localeId new locale
   */
  setLocaleId(localeId: Locale.LocaleId): void {
    const match = Arr.findFirst(this.supported, _findEquivalent(localeId));

    if (O.isSome(match)) {
      this._localeId.set(match.value);
    }
  }

  private _initFromInitializer(localeIds: readonly Locale.LocaleId[]): void {
    const match = Arr.findFirst(localeIds, (id) =>
      Arr.findFirst(this.supported, _findEquivalent(id)),
    );

    if (O.isSome(match)) {
      this._localeId.set(match.value);
      return;
    }
  }
}

const _findEquivalent =
  (localeId: Locale.LocaleId): Predicate.Predicate<Locale.LocaleId> =>
  (other) =>
    Locale.Equivalence(localeId, other);
