import { inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, webStorageItemAccessor } from '@fmnts/common/storage';
import { isNotNull } from '@fmnts/core';
import {
  AuthApiActions,
  AuthRootActions,
  selectAuth,
  selectSession,
} from '@formunauts/shared/auth/data-access';
import { Auth } from '@formunauts/shared/domain';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Arr from 'effect/Array';
import { concatMap, EMPTY, filter, of, tap } from 'rxjs';

type PersistedAuthInfo = Omit<Auth.LegacyAuthInfo, 'permissions'> & {
  // only persist extrinsic permissions
  permissions?: ReadonlyArray<Auth.ExtrinsicPermission>;
};

interface AuthData {
  authInfo?: PersistedAuthInfo | null;
  access_token?: string | null;
  refresh_token?: string | null;
}

/**
 * Effects for persisting session tokens for page reloads.
 * This functions as a bridge to make the session token available to the old frontend.
 */
@Injectable()
export class AuthTokenEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  /**
   * Provides access for persistent storage for the session tokens.
   */
  private readonly _storage = webStorageItemAccessor<AuthData | null>({
    storage: inject(LOCAL_STORAGE),
    // ***NOTE***: This is the key that the old frontend uses to get session tokens
    key: 'ngStorage-tokenData',
    defaultState: {
      authInfo: null,
      access_token: null,
      refresh_token: null,
    },
  });

  hydrate$ = createEffect(() => {
    return this.actions$.pipe(ofType(ROOT_EFFECTS_INIT)).pipe(
      concatMap(() => {
        const { access_token, authInfo, refresh_token } =
          this._storage.persisted() ?? {};

        if (access_token && refresh_token && authInfo) {
          return of(
            AuthRootActions.hydrate({
              authInfo: {
                ...authInfo,
                permissions: Arr.copy(authInfo.permissions ?? []),
              },
              session: { access_token, refresh_token },
            }),
          );
        }

        return EMPTY;
      }),
    );
  });

  persistTokens$ = createEffect(
    () => {
      return this.store.select(selectSession).pipe(
        filter(isNotNull),
        tap((session) => {
          this._storage.merge({
            access_token: session.access_token,
            refresh_token: session.refresh_token,
          });
        }),
      );
    },
    { dispatch: false },
  );

  persistAuthInfo$ = createEffect(
    () => {
      return this.store.select(selectAuth).pipe(
        filter(isNotNull),
        tap((authInfo) => {
          this._storage.merge({ authInfo: _toPersistedAuthInfo(authInfo) });
        }),
      );
    },
    { dispatch: false },
  );

  clearPersisted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
          AuthRootActions.forgetUser,
        ),
        tap(() => {
          this._storage.removePersisted();
        }),
      );
    },
    { dispatch: false },
  );
}

function _toPersistedAuthInfo(
  authInfo: Auth.LegacyAuthInfo,
): PersistedAuthInfo {
  return {
    ...authInfo,
    permissions: Arr.filter(authInfo.permissions, Auth.isExtrinsicPermission),
  };
}
