import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import {
  FeatureFlagGuardConfig,
  featureFlagCanActivate,
  featureFlagCanLoad,
} from '@fmnts/common/feature-flags';
import { Customer } from '@formunauts/shared/domain';
import { CustomerFeatureService } from './customer-feature.service';

/**
 * Can Activate guard that checks for customer feature flags.
 *
 * @param config Guard configuration.
 * @returns
 * Guard function
 */
export function customerFeatureFlagCanActivate(
  config: FeatureFlagGuardConfig<Customer.Features>,
): CanActivateFn {
  return () =>
    featureFlagCanActivate(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(CustomerFeatureService),
    );
}

/**
 * Can Load guard that checks for customer feature flags.
 *
 * @param config Guard configuration
 * @returns
 * Guard function.
 */
export function customerFeatureFlagCanLoad(
  config: FeatureFlagGuardConfig<Customer.Features>,
): CanActivateFn {
  return () =>
    featureFlagCanLoad(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(CustomerFeatureService),
    );
}
