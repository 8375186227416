import { InjectionToken } from '@angular/core';
import { ToValuePipeMapping } from '@fmnts/common';
import { TranslationId } from '@fmnts/i18n';
import { SupportRequest } from '@formunauts/shared/support/domain';

export type SupportUiI18nOptions = Readonly<{
  translations: Readonly<{
    requestType: ToValuePipeMapping<
      `${SupportRequest.SupportRequestType}`,
      TranslationId
    >;
  }>;
}>;

/**
 * Injection token to provide i18n options for support.
 */
export const SHARED_SUPPORT_UI_I18N_OPTIONS =
  new InjectionToken<SupportUiI18nOptions>(
    'libs.shared.support.ui.i18n.options',
    {
      providedIn: 'root',
      factory: () => ({
        translations: {
          requestType: {
            [SupportRequest.SupportRequestType.Crash]:
              'support_request_type_crash',
            [SupportRequest.SupportRequestType.Critical]:
              'support_request_type_critical',
            [SupportRequest.SupportRequestType.Minor]:
              'support_request_type_minor',
            [SupportRequest.SupportRequestType.Other]:
              'support_request_type_other',
          },
        },
      }),
    },
  );
