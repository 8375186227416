import { Injectable, inject } from '@angular/core';
import { SupportRequest } from '@formunauts/shared/support/domain';
import { Observable } from 'rxjs';
import { SUPPORT_REQUEST_CONTEXT_RESOLVER_FN } from './support-request-context';
import { SUPPORT_REQUEST_USER_DATA_RESOLVE_FN } from './support-request-defaults';
import {
  SupportRequestAddDto,
  SupportRequestAddWithDefaultsDto,
  SupportRequestScreenshotChangeDto,
} from './support-request.entity';
import { SupportRequestRepository } from './support-request.repository';

/** Service for managing support requests. */
@Injectable()
export class SupportRequestService {
  private readonly _resolveContext = inject(
    SUPPORT_REQUEST_CONTEXT_RESOLVER_FN,
  );
  private readonly _resolveUserData = inject(
    SUPPORT_REQUEST_USER_DATA_RESOLVE_FN,
  );
  private readonly _repo = inject<
    SupportRequestRepository<SupportRequest.RequestContext>
  >(SupportRequestRepository);

  /**
   * Adds a new support request.
   *
   * @returns
   * An Observable that emits with the entity.
   */
  add(
    dto: SupportRequestAddWithDefaultsDto,
  ): Observable<SupportRequest.SupportRequest<SupportRequest.RequestContext>> {
    const context = this._resolveContext();
    const entity: SupportRequestAddDto<SupportRequest.RequestContext> = {
      timestamp: new Date(),
      ...dto,
      context,
    };

    return this._repo.add(entity);
  }

  /**
   * Updates or inserts a screenshot for a support request.
   *
   * @param entity Support request to attach the screenshot to.
   * @param changes Changes
   * @returns
   * An Observable that emits with the updated entity.
   */
  upsertScreenshot(
    entity: SupportRequest.SupportRequest<SupportRequest.RequestContext>,
    changes: SupportRequestScreenshotChangeDto,
  ): Observable<SupportRequest.SupportRequest<SupportRequest.RequestContext>> {
    return this._repo.upsertScreenshot(entity, changes);
  }

  /**
   * @returns
   * Default user data.
   */
  getDefaultUserData(): Partial<SupportRequest.SupportRequestUserData> {
    return this._resolveUserData();
  }
}
