import { Injectable } from '@angular/core';
import { includedIn } from '@fmnts/core/collection';
import { filter } from 'rxjs';
import { GoogleMapsLoaderService } from './google-maps-loader.service';

type PlaceResult = google.maps.places.PlaceResult;

interface AddressComponentMapping {
  [fieldKey: string]: string[];
}

export interface AddressComponentMappingResult {
  [fieldKey: string]: {
    shortName: string;
    longName: string;
  };
}

/**
 * Service for interacting with the google maps API
 */
@Injectable()
export class GoogleMapsService {
  constructor(private loaderService: GoogleMapsLoaderService) {}

  /**
   * Emits with a stable instance of the api
   */
  public get stable$() {
    return this.loaderService.api$
      .asObservable()
      .pipe(filter((api) => api !== undefined));
  }

  /**
   * Maps the address components of a `PlaceResult` according to the specified mapping
   *
   * @param place The place result
   * @param mapping The mapping
   */
  public mapAddressComponents(
    place: PlaceResult,
    mapping: AddressComponentMapping,
  ): AddressComponentMappingResult {
    const { address_components: components } = place;
    if (!mapping || !components) {
      return undefined;
    }

    const getValue = (componentTypes: string[]) =>
      components.find((c) => c.types.some(includedIn(componentTypes)));

    return Object.entries(mapping)
      .map<[string, google.maps.GeocoderAddressComponent]>(
        ([fieldKey, componentTypes]) => [fieldKey, getValue(componentTypes)],
      )
      .reduce(
        (result, [fieldKey, value]) => ({
          ...result,
          [fieldKey]: value
            ? { shortName: value.short_name, longName: value.long_name }
            : undefined,
        }),
        {},
      );
  }

  /**
   * @returns
   * The node representing the open suggestion box
   */
  public findOpenAutocompleteBox() {
    return Array.prototype.find.call(
      document.querySelectorAll('.pac-container'),
      (node) => node.style.display !== 'none',
    );
  }
}
