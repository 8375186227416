import * as Data from 'effect/Data';

export class UnexpectedError extends Data.TaggedError('UnexpectedError') {}

export class UnexpectedServerError extends Data.TaggedError(
  'UnexpectedServerError',
) {}
export class ServerUnavailableError extends Data.TaggedError(
  'ServerUnavailableError',
) {}

export class EntityNotFoundError extends Data.TaggedError(
  'EntityNotFoundError',
) {}

export type InfrastructureError =
  | ServerUnavailableError
  | UnexpectedServerError;

/** Common error cases */
export type GetEntityError<TNotFound> =
  | TNotFound
  | InfrastructureError
  | UnexpectedError;
