import { Injectable, InjectionToken, inject } from '@angular/core';
import {
  SupportRequestApi,
  SupportRequest as SupportRequestApiModel,
} from '@fmnts/api';
import {
  TicketingRequestCreateDto,
  ZendeskTicketingRequestApi,
} from '@fmnts/api/vendors/zendesk';
import { isDefined } from '@fmnts/core';
import {
  SupportRequestAddDto,
  SupportRequestRepository,
  SupportRequestScreenshotChangeDto,
} from '@formunauts/shared/support/data-access';
import { SupportRequest } from '@formunauts/shared/support/domain';
import * as Sentry from '@sentry/angular';
import {
  Observable,
  catchError,
  defer,
  forkJoin,
  iif,
  map,
  mergeMap,
  of,
} from 'rxjs';

export interface AppSupportRequestContext {
  logs: null;
  path: string;
  version: string;
  local_storage: Record<string, string>;
  sentry: null;
  state: Record<string, unknown> | null;
  system: null;
  data_trash: null;
}

type AppSupportRequest =
  SupportRequest.SupportRequest<AppSupportRequestContext>;

interface AppSupportRequestRepositoryConfig {
  sentry: {
    /** For which support request types to create a sentry user feedback. */
    createForTypes: Set<SupportRequest.SupportRequestType>;
    captureMessage: string;
    anonymousUser: Readonly<{
      name: string;
      email: string;
    }>;
  };
  zendesk: {
    /** For which support request types to create a zendesk ticket. */
    createForTypes: Set<SupportRequest.SupportRequestType>;
    anonymousUser: Readonly<{
      name: string;
      email: string;
    }>;
  };
}

/** Configuration options for  */
const APP_SUPPORT_REQUEST_REPOSITORY_CONFIG =
  new InjectionToken<AppSupportRequestRepositoryConfig>(
    'app.core.support-request.repository-config',
    {
      factory: () => ({
        sentry: {
          createForTypes: new Set([
            SupportRequest.SupportRequestType.Crash,
            SupportRequest.SupportRequestType.Critical,
            SupportRequest.SupportRequestType.Minor,
          ]),
          captureMessage: 'Support Request',
          anonymousUser: {
            name: 'Anonymous',
            email: '',
          },
        },
        zendesk: {
          createForTypes: new Set(
            Object.values(SupportRequest.SupportRequestType),
          ),
          anonymousUser: {
            name: 'Anonymous',
            email: '',
          },
        },
      }),
    },
  );

const FALSE$ = of(false as const);

/**
 * Implements access to the support request API.
 */
@Injectable({ providedIn: 'root' })
export class AppSupportRequestRepository extends SupportRequestRepository<AppSupportRequestContext> {
  private readonly api = inject(SupportRequestApi);
  private readonly zendeskTicketRequestApi = inject(ZendeskTicketingRequestApi);
  private readonly config = inject(APP_SUPPORT_REQUEST_REPOSITORY_CONFIG);
  private readonly mapper = inject(SupportRequestApiRepositoryMapper);
  private readonly zendeskMapper = inject(SupportRequestToZendeskRequestMapper);

  override add(
    dto: SupportRequestAddDto<AppSupportRequestContext>,
  ): Observable<AppSupportRequest> {
    return this.api
      .create({
        type: dto.type,
        email: dto.email ?? '',
        description: dto.description,
        path: dto.context.path,
        timestamp: dto.timestamp,
        // TODO: implement
        appContext: {},
        deletedFormData: [],
        lastRefresh: null,
        lastSentryTransactions: [],
        localStorage: {},
        logs: [],
        version: dto.context.version,
      })
      .pipe(
        map(this.mapper.mapFrom),
        mergeMap((supportRequest) =>
          forkJoin({
            zendesk: this._createZendeskTicket(supportRequest).pipe(
              catchError(() => FALSE$),
            ),
            sentry: this._createSentryUserFeedback(supportRequest).pipe(
              catchError(() => FALSE$),
            ),
          }).pipe(map(() => supportRequest)),
        ),
      );
  }

  override upsertScreenshot(
    entity: AppSupportRequest,
    changes: SupportRequestScreenshotChangeDto,
  ): Observable<AppSupportRequest> {
    const apiModel = this.mapper.mapTo(entity);

    return this.api
      .update(apiModel, {
        screenshot: changes.screenshot,
      })
      .pipe(map(this.mapper.mapFrom));
  }

  private _createZendeskTicket(supportRequest: AppSupportRequest) {
    const { zendesk } = this.config;
    return iif(
      () => zendesk.createForTypes.has(supportRequest.type),
      defer(() =>
        this.zendeskTicketRequestApi.create(
          this.zendeskMapper.mapTo(supportRequest, this.config.zendesk),
        ),
      ),
      FALSE$,
    );
  }

  private _createSentryUserFeedback(supportRequest: AppSupportRequest) {
    const sentryConfig = this.config.sentry;
    if (!sentryConfig.createForTypes.has(supportRequest.type)) {
      return FALSE$;
    }

    const { email, description } = supportRequest;
    const eventId = Sentry.captureMessage(sentryConfig.captureMessage);

    Sentry.captureUserFeedback({
      event_id: eventId,
      email: email ?? sentryConfig.anonymousUser.email,
      comments: description,
      name: sentryConfig.anonymousUser.name,
    });

    return of(true);
  }
}

/**
 * Mapper between Support Request Entity and Model.
 */
@Injectable({ providedIn: 'root' })
class SupportRequestApiRepositoryMapper {
  mapFrom = (model: SupportRequestApiModel): AppSupportRequest => ({
    id: model.id,
    url: model.url,
    timestamp: model.timestamp,
    type: model.type as SupportRequest.SupportRequestType,
    description: model.description,
    email: model.email,
    screenshotUrl: model.screenshot,
    // TODO: implement
    context: {
      data_trash: null,
      local_storage: {},
      logs: null,
      path: model.path,
      sentry: null,
      state: null,
      system: null,
      version: model.version,
    },
  });

  mapTo = (entity: AppSupportRequest): SupportRequestApiModel => {
    const id = entity.id;
    const url = entity.url;

    if (!isDefined(id) || !isDefined(url)) {
      throw new Error(
        `Can not convert AppSupportRequest to SupportRequestApiModel, because required properties are missing.`,
      );
    }

    const model: SupportRequestApiModel = {
      id,
      url,
      type: entity.type,
      description: entity.description,
      email: entity.email ?? '',
      path: entity.context.path,
      version: entity.context.version,
      screenshot: entity.screenshotUrl ?? null,
      timestamp: entity.timestamp,
      // TODO: implement
      appContext: {},
      deletedFormData: [],
      lastRefresh: null,
      lastSentryTransactions: [],
      logs: [],
      localStorage: {},
    };

    return model;
  };
}

@Injectable({ providedIn: 'root' })
class SupportRequestToZendeskRequestMapper {
  mapTo = (
    entity: AppSupportRequest,
    config: AppSupportRequestRepositoryConfig['zendesk'],
  ): TicketingRequestCreateDto => {
    const { email, description, type } = entity;
    return {
      requester: {
        name: config.anonymousUser.name,
        email: email ?? config.anonymousUser.email,
      },
      subject: `Feedback form: new ${type}`,
      comment: { body: description },
    };
  };
}
