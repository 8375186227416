import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { I18nModule } from '@fmnts/i18n';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-solid-svg-icons';
import * as Arr from 'effect/Array';

@Component({
  selector: `fmnts-pagination`,
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
  standalone: true,
  imports: [FmntsButtonModule, FmntsIconsModule, I18nModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  /**
   * Number of elements in total.
   * Defaults to 0.
   */
  @Input() get count(): number {
    return this._count();
  }
  set count(value: NumberInput) {
    this._count.set(Math.max(coerceNumberProperty(value), 0));
  }
  protected readonly _count = signal(0);

  /**
   * Number of the current page.
   * Defaults to 1.
   */
  @Input() get currentPage(): number {
    return this._currentPage();
  }
  set currentPage(value: NumberInput) {
    this._currentPage.set(Math.max(coerceNumberProperty(value), 1));
  }
  protected readonly _currentPage = signal(1);

  /**
   * Size of elements on the page.
   * Defaults to 0.
   */
  @Input() get pageSize(): number {
    return this._pageSize();
  }
  set pageSize(value: NumberInput) {
    this._pageSize.set(Math.max(coerceNumberProperty(value), 0));
  }
  protected readonly _pageSize = signal(0);

  @Input({ transform: booleanAttribute })
  get showTotals(): boolean {
    return this._showTotals();
  }
  set showTotals(value: BooleanInput) {
    this._showTotals.set(coerceBooleanProperty(value));
  }
  protected readonly _showTotals = signal(false);

  /** Emits the current page. */
  @Output() currentPageChange = new EventEmitter<number>();

  /** @internal */
  protected readonly _iconFirst = faAngleDoubleLeft;
  /** @internal */
  protected readonly _iconPrev = faAngleLeft;
  /** @internal */
  protected readonly _iconNext = faAngleRight;
  /** @internal */
  protected readonly _iconLast = faAngleDoubleRight;

  protected readonly nextPage = computed(() => this._currentPage() + 1);
  protected readonly previousPage = computed(() => this._currentPage() - 1);

  protected readonly lastPage = computed(() =>
    Math.ceil(this._count() / this._pageSize()),
  );

  protected readonly prevButtonDisabled = computed(
    () => this._currentPage() === 1,
  );
  protected readonly nextButtonDisabled = computed(
    () => this._count() === 0 || this._currentPage() === this.lastPage(),
  );

  protected readonly resultsStart = computed(
    () => this._pageSize() * (this._currentPage() - 1),
  );
  protected readonly resultsEnd = computed(() =>
    this._currentPage() === this.lastPage()
      ? this._count()
      : this._pageSize() * this._currentPage(),
  );

  private readonly areEndsExpanded = computed(() =>
    [1, 2, this.lastPage() - 1, this.lastPage()].includes(this._currentPage()),
  );

  protected readonly visibleRanges = computed<number[][]>(() => {
    if (this.count === 0) {
      return [];
    }

    const lastPage = this.lastPage();
    const currentPage = this._currentPage();
    if (lastPage <= 7) {
      return [Arr.range(1, lastPage)];
    }
    if (this.areEndsExpanded()) {
      return [Arr.range(1, 3), Arr.range(lastPage - 2, lastPage)];
    } else if (currentPage <= 4) {
      return [Arr.range(1, 5), [lastPage]];
    } else if (currentPage === lastPage - 2) {
      return [[1], Arr.range(lastPage - 4, lastPage)];
    }

    return [[1], Arr.range(currentPage - 1, currentPage + 1), [lastPage]];
  });

  protected navigateTo(page: number): void {
    if (page !== this.currentPage) {
      this.currentPageChange.emit(page);
    }
  }
}
