import { InjectionToken } from '@angular/core';
import { TranslationId } from '@fmnts/i18n';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft,
  faCloudExclamation,
  faDiamondExclamation,
  faRotate,
  faTrafficCone,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons';

export interface SharedUiCommonEmptyStates {
  /**
   * Use when the user is offline and the app can't connect to the server.
   */
  offline: {
    title: TranslationId;
    description: TranslationId;
    icon: IconDefinition;
  };
  /**
   * Use when a request timed out.
   */
  timeout: {
    title: TranslationId;
    description: TranslationId;
    icon: IconDefinition;
  };
  /**
   * Use when the backend encountered an unexpected error.
   */
  serverUnexpected: {
    title: TranslationId;
    description: TranslationId;
    icon: IconDefinition;
  };
  /**
   * Use when the backend is not accepting requests due to maintainence.
   */
  serverUnavailable: {
    title: TranslationId;
    description: TranslationId;
    icon: IconDefinition;
  };
  /**
   * Use for unexpected errors.
   */
  unexpected: {
    title: TranslationId;
    description: TranslationId;
    icon: IconDefinition;
  };
}

export interface SharedUiCommonActions {
  /** Use for navigating to a previous view. */
  back: {
    label: TranslationId;
    icon: IconDefinition;
  };
  /** Use for saving. */
  save: {
    label: TranslationId;
    icon: IconDefinition;
  };
  /** Use for retrying an action that failed. */
  retry: {
    label: TranslationId;
    icon: IconDefinition;
  };
}

/**
 * DI token that provides common empty states for shared UI.
 */
export const SHARED_UI_COMMON_EMPTY_STATES =
  new InjectionToken<SharedUiCommonEmptyStates>(
    '@formunauts/shared/ui/common-empty-states',
    {
      providedIn: 'root',
      factory: () => ({
        timeout: {
          title: 'client_timeout_error_title',
          description: 'client_timeout_error_description',
          icon: faWifiSlash,
        },
        offline: {
          title: 'client_offline_error_title',
          description: 'client_offline_error_description',
          icon: faWifiSlash,
        },
        serverUnavailable: {
          title: 'server_unavailable_error_title',
          description: 'server_unavailable_error_description',
          icon: faTrafficCone,
        },
        serverUnexpected: {
          title: 'server_unexpected_error_title',
          description: 'server_unexpected_error_description',
          icon: faCloudExclamation,
        },
        unexpected: {
          title: 'unknown_error',
          description: 'unknown_error_description',
          icon: faDiamondExclamation,
        },
      }),
    },
  );

/**
 * DI token that provides common empty states for shared UI.
 */
export const SHARED_UI_COMMON_ACTIONS =
  new InjectionToken<SharedUiCommonActions>(
    '@formunauts/shared/ui/common-actions',
    {
      providedIn: 'root',
      factory: () => ({
        back: {
          label: 'back',
          icon: faChevronLeft,
        },
        save: {
          label: 'save',
          icon: faChevronLeft,
        },
        retry: {
          label: 'retry',
          icon: faRotate,
        },
      }),
    },
  );
