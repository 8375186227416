import * as Brand from 'effect/Brand';
import * as Eq from 'effect/Equivalence';
import * as S from 'effect/String';

/**
 * A locale identifier is a string that consists of:
 * 1. A language subtag with 2–3 or 5–8 letters
 * 2. A script subtag with 4 letters (optional)
 * 3. A region subtag with either 2 letters or 3 digits (optional)
 * 4. One or more variant subtags (all of which must be unique), each with either 5–8 alphanumerals or a digit followed by 3 alphanumerals (optional)
 * 5. One or more BCP 47 extension sequences (optional)
 * 6. A private-use extension sequence (optional)
 *
 * Each subtag and sequence are separated by hyphens.
 * Locale identifiers are **case-insensitive** ASCII.
 * However, it's conventional to use
 * - title case (the first letter is capitalized, successive letters are lower case) for script subtags,
 * - upper case for region subtags,
 * - and lower case for everything else.
 *
 * Subtags identifying languages, scripts, regions (including countries), and (rarely used) variants
 * are registered in the [IANA Language Subtag Registry](https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry).
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument}
 *
 * @example
 *
 * Locale.LocaleId('hi'); // Hindi (language)
 * Locale.LocaleId('de-AT'); // German (language) as used in Austria (region)
 * Locale.LocaleId('zh-Hans-CN'); // Chinese (language) written in simplified characters (script) as used in China (region)
 * Locale.LocaleId('en-emodeng'); // English (language) in the "Early modern English" dialect (variant)
 */
export type LocaleId = string & Brand.Brand<'LocaleId'>;
export const LocaleId = Brand.nominal<LocaleId>();
export const Equivalence = Eq.mapInput<string, LocaleId>(
  Eq.string,
  S.toLowerCase,
);

// TODO(arch): Move to shared and directly use `LocaleId` on domain models
/**
 * Coerces a legacy style locale ID.
 *
 * @param localeId
 * Legacy locale identifier in the format of `[language[_regionCode]]`.
 */
export function coerceLegacyLocaleId(
  localeId: Brand.Brand.Unbranded<LocaleId>,
): LocaleId {
  return LocaleId(localeId.replace('_', '-'));
}
