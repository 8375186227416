import { NgModule } from '@angular/core';
import {
  ColumnLayoutColumnDirective,
  ColumnLayoutDirective,
} from './column-layout.directive';
import { FlexLayoutDirective } from './flex-layout.directive';
import { GridLayoutDirective } from './grid-layout.directive';
import { LayoutDirective, LayoutSlotDirective } from './layout.directive';

@NgModule({
  declarations: [
    ColumnLayoutDirective,
    ColumnLayoutColumnDirective,
    FlexLayoutDirective,
    GridLayoutDirective,
    LayoutDirective,
    LayoutSlotDirective,
  ],
  exports: [
    ColumnLayoutDirective,
    ColumnLayoutColumnDirective,
    FlexLayoutDirective,
    GridLayoutDirective,
    LayoutDirective,
    LayoutSlotDirective,
  ],
})
export class FmntsLayoutModule {}
