import { computed, inject, Injectable } from '@angular/core';
import { Repository } from '@fmnts/common/indexed-db';
import { isDefined } from '@fmnts/core';
import { parseISO } from '@fmnts/core/chronos';
import {
  FormDataTrashConfig,
  FormDataTrashRepository,
} from '@formunauts/shared/diagnostic/data-access';
import { TrashItem } from '@formunauts/shared/diagnostic/domain';
import { UserStore } from '@formunauts/shared/user/data-access';
import * as O from 'effect/Option';
import { concatMap, map, Observable, zip } from 'rxjs';
import {
  AppDiagnosticsDataDbSchema,
  TrashedFormDataIdbModel,
} from './app-diagnostics-data.db';

@Injectable()
export class AppFormDataTrashIdbRepository implements FormDataTrashRepository {
  private readonly _db =
    inject<Repository<AppDiagnosticsDataDbSchema>>(Repository);

  private readonly _config = inject(FormDataTrashConfig);
  private readonly _mapper = inject(TrashedFormDataIdbRepositoryMapper);
  private readonly _diagnosticsMeta = inject(AppDiagnosticsMetaService);

  put<TContent>(
    dto: TrashItem.AddTrashedFormDataDto<TContent>,
  ): Observable<TrashItem.TrashedFormData<TContent>> {
    const entity = this._dtoToEntity(dto);
    const model = this._mapper.mapTo(entity);

    return this._db
      .add('diagnostic_form_data_trash', model)
      .pipe(map(() => entity));
  }

  getAll(): Observable<TrashItem.TrashedFormData<unknown>[]> {
    const allEntries$ = this._db.getAll('diagnostic_form_data_trash');

    return allEntries$.pipe(
      map((entries) => entries.map(this._mapper.mapFrom)),
    );
  }

  removeByIds(ids: number[]): Observable<number> {
    return zip(
      ...ids.map((id) => this._db.remove('diagnostic_form_data_trash', id)),
    ).pipe(map((e) => e.length));
  }

  expireEntries(): Observable<number> {
    const allEntries$ = this._db.getAll('diagnostic_form_data_trash');

    return allEntries$.pipe(
      map((entries) =>
        entries
          .slice(0, Math.max(0, entries.length - this._config.maxEntries))
          .map((e) => e.id)
          .filter(isDefined),
      ),
      concatMap((idsToDelete) => this.removeByIds(idsToDelete)),
    );
  }

  private _dtoToEntity<TContent>(
    dto: TrashItem.AddTrashedFormDataDto<TContent>,
  ): TrashItem.TrashedFormData<TContent> {
    const meta = this._diagnosticsMeta.getMeta();
    return {
      timestamp: new Date(),
      content: dto.content,
      name: dto.name,
      origin: dto.origin,
      username: meta.username,
    };
  }
}

/**
 * Mapper between Entity and Model.
 */
@Injectable({ providedIn: 'root' })
class TrashedFormDataIdbRepositoryMapper {
  mapFrom = <T>(
    model: TrashedFormDataIdbModel<T>,
  ): TrashItem.TrashedFormData<T> => ({
    id: model.id,
    content: model.content,
    name: model.name,
    origin: model.origin,
    timestamp: parseISO(model.timestamp),
    username: O.fromNullable(model.username),
  });

  mapTo = <T>(
    entity: TrashItem.TrashedFormData<T>,
  ): TrashedFormDataIdbModel<T> => {
    const model: TrashedFormDataIdbModel<T> = {
      timestamp: entity.timestamp.toISOString(),
      content: entity.content,
      name: entity.name,
      origin: entity.origin,
      username: O.getOrNull(entity.username),
    };

    if (isDefined(entity.id)) {
      model.id = entity.id;
    }

    return model;
  };
}

interface DiagnosticsMeta {
  username: O.Option<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AppDiagnosticsMetaService {
  private readonly userStore = inject(UserStore);

  private readonly diagnosticsMeta = computed<DiagnosticsMeta>(() => ({
    username: this.userStore.username(),
  }));

  /**
   * Collects diagnostics meta information.
   *
   * @returns
   */
  getMeta(): DiagnosticsMeta {
    return this.diagnosticsMeta();
  }
}
