import { Injectable, computed, inject } from '@angular/core';
import {
  DeviceGeolocationService,
  toGeoCoordinatesError,
} from '@fmnts/common/geolocation';
import {
  AsyncData,
  AsyncDataState,
  maybeLoadedAsyncData,
  maybeLoadedAsyncError,
} from '@fmnts/common/store';
import { tapResponse } from '@ngrx/operators';
import {
  PartialStateUpdater,
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, pipe } from 'rxjs';
import { GeolocationService } from './geolocation.service';
import {
  GeoCoordinates,
  GeoCoordinatesError,
  GeoCoordinatesErrorCode,
} from './shared-geolocation.model';

interface GeolocationState {
  asyncCoordinates: AsyncData<GeoCoordinates, GeoCoordinatesError>;
}

const BaseGeolocationStore = signalStore(
  { protectedState: false },
  withState<GeolocationState>({
    asyncCoordinates: { state: AsyncDataState.Init },
  }),
  withComputed(({ asyncCoordinates }) => ({
    error: computed(() => maybeLoadedAsyncError(asyncCoordinates())),
    coordinates: computed(() => maybeLoadedAsyncData(asyncCoordinates())?.data),
  })),
  withComputed(({ error }) => ({
    permissionDenied: computed(
      () => error()?.code === GeoCoordinatesErrorCode.PermissionDenied,
    ),
  })),
  withMethods((state, geolocation = inject(DeviceGeolocationService)) => ({
    updatePosition: rxMethod<void>(
      pipe(
        exhaustMap(() =>
          geolocation.currentPosition$().pipe(
            tapResponse({
              next: (pos) => {
                patchState(state, _asyncDataFromResponse(pos));
              },
              error: (error) => {
                patchState(state, _asyncDataFromError(error));
              },
            }),
          ),
        ),
      ),
    ),
  })),
);

@Injectable({ providedIn: 'root' })
export class GeolocationStore
  extends BaseGeolocationStore
  implements GeolocationService {}

function _asyncDataFromResponse({
  coords,
  timestamp,
}: GeolocationPosition): PartialStateUpdater<GeolocationState> {
  return (state) => ({
    asyncCoordinates: {
      state: AsyncDataState.Loaded,
      data: {
        latitude: coords.latitude,
        longitude: coords.longitude,
      },
      refresh: new Date(timestamp).toISOString(),
    },
  });
}

function _asyncDataFromError(
  originalError: unknown,
): PartialStateUpdater<GeolocationState> {
  return (state) => {
    const error = toGeoCoordinatesError(originalError);

    switch (error.code) {
      case GeoCoordinatesErrorCode.PermissionDenied:
        return {
          asyncCoordinates: {
            state: { error },
          },
        };
      default:
        return {
          asyncCoordinates: {
            ...state.asyncCoordinates,
            state: { error },
          },
        };
    }
  };
}
