import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';
import * as Locale from './locale.model';

/**
 * A function that returns an array of prefered locales.
 */
export type GetPreferedLocales = () => readonly Locale.LocaleId[];

/**
 * DI token for getting the prefered locales.
 */
export const GET_PREFERED_LOCALES = new InjectionToken<GetPreferedLocales>(
  '@fmnts.i18n.get-prefered-locales',
);

/**
 * DI token to get a function that reads the browser locales.
 */
export const READ_BROWSER_LOCALES = new InjectionToken<
  () => O.Option<readonly Locale.LocaleId[]>
>('@fmnts.i18n.read-browser-locales', {
  providedIn: 'platform',
  factory:
    (doc = inject(DOCUMENT)) =>
    () =>
      Fn.pipe(
        O.fromNullable(doc.defaultView?.navigator),
        O.map(_getBrowserLocales),
      ),
});

function _getBrowserLocales(nav: Navigator): readonly Locale.LocaleId[] {
  return Fn.pipe(nav.languages, Arr.map(Locale.LocaleId));
}
