@switch (variant) {
  @case ('display') {
    <fmnts-rocket [animateThrusts]="animateThrusts" [color]="color" />
    <fmnts-logo-type [color]="color" [tagline]="tagline" />
  }
  @case ('regular') {
    <fmnts-rocket [animateThrusts]="animateThrusts" [color]="color" />
    <fmnts-logo-type stretchTagline [color]="color" [tagline]="tagline" />
  }

  @case ('icon') {
    <fmnts-rocket [animateThrusts]="animateThrusts" [color]="color" />
  }
}
