import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  OperatorFunction,
  Subscription,
  fromEvent,
  map,
  merge,
} from 'rxjs';

type ConnectionStatus = 'online' | 'offline';

/**
 * @param desiredStatus The status with which `true` should be emitted
 *
 * @returns
 * Operator that can be used to map the connection status to
 * a boolean value.
 */
export function hasConnectionStatus(
  desiredStatus: ConnectionStatus,
): OperatorFunction<ConnectionStatus, boolean> {
  return map((status) => status === desiredStatus);
}

/**
 * Service that allows provides access to the connection status of
 * the browser.
 * It makes use of the browsers `onLine` property and events to
 * determine changes in this property.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
 */
@Injectable({ providedIn: 'root' })
export class ConnectionService implements OnDestroy {
  private readonly _subscription = new Subscription();
  private readonly connectionSubject = new BehaviorSubject<ConnectionStatus>(
    this._getStatusFromNavigator(),
  );

  /**
   * Emits on changes of the connection status
   */
  public readonly connectionStatus$ = this.connectionSubject.asObservable();

  /**
   * The current connection status of the browser
   */
  get connectionStatus(): ConnectionStatus {
    return this.connectionSubject.value;
  }

  /**
   * `true` if the browser isn't offline.
   * This doesn't necessarily mean that the browser can connect to
   * the internet.
   */
  get isOnline(): boolean {
    return this.connectionStatus === 'online';
  }

  /**
   * `true` if the browser is offline.
   */
  get isOffline(): boolean {
    return !this.isOnline;
  }

  constructor(@Inject(DOCUMENT) private readonly _doc: Document) {
    const connectionStatus$ = _doc.defaultView
      ? this._connectionStatus$(_doc.defaultView)
      : EMPTY;

    this._subscription.add(
      connectionStatus$.subscribe((status) => {
        this.connectionSubject.next(status);
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.connectionSubject.complete();
  }

  private _getStatusFromNavigator(): ConnectionStatus {
    const { defaultView } = this._doc;
    if (defaultView) {
      return defaultView.navigator.onLine ? 'online' : 'offline';
    }

    return 'online';
  }

  private _connectionStatus$(view: Window) {
    return merge(
      fromEvent(view, 'load').pipe(map(() => this._getStatusFromNavigator())),
      fromEvent(view, 'online').pipe(map((): ConnectionStatus => 'online')),
      fromEvent(view, 'offline').pipe(map((): ConnectionStatus => 'offline')),
    );
  }
}
