import {
  APP_INITIALIZER,
  EnvironmentProviders,
  inject,
  makeEnvironmentProviders,
  Provider,
} from '@angular/core';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';
import { DateService } from './date.service';
import {
  GET_PREFERED_LOCALES,
  GetPreferedLocales,
  READ_BROWSER_LOCALES,
} from './i18n.tokens';
import { LocaleOptions, LocaleService } from './locale.service';
import { TranslationService } from './translation.service';
import type { TranslationHash } from './translation.types';

export enum LocalizationFeatureKind {
  PreferedLocales,
}

export interface LocalizationFeature {
  /** feature kind. */
  kind: LocalizationFeatureKind;
  /** Providers for this feature. */
  providers: Provider[];
}

interface TranslateScopeConfig {
  /**
   * Set of translations
   */
  translations: TranslationHash;

  /**
   * Set of languages, should be used in that order
   */
  order: string[];
}

interface LocalizationModuleConfig {
  readonly translate: TranslateScopeConfig;
  readonly locale: LocaleOptions;
}

/**
 * Use this in your application root module to provide localization.
 *
 * @param config Configuration object
 *
 * @example
 * @NgModule({
 *   providers: [provideL10n({...})]
 * })
 * class AppModule {}
 */
export function provideL10n(
  config: LocalizationModuleConfig,
  ...features: readonly LocalizationFeature[]
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: TranslationService,
      useFactory: () => setupTranslationService(config.translate),
    },
    {
      provide: LocaleOptions,
      useValue: config.locale,
    },
    LocaleService,
    DateService,
    {
      // Eagerly create the DateService to keep moment.js locale up to date
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (ds = inject(DateService)) => Fn.constVoid,
    },
    ...features.flatMap((f) => f.providers),
  ]);
}

/**
 * Provides the browser locales as the prefered locales.
 */
export function withLocalesFromBrowser(): LocalizationFeature {
  return _makeProviderFeature(LocalizationFeatureKind.PreferedLocales, [
    {
      provide: GET_PREFERED_LOCALES,
      useFactory:
        (
          getBrowserLocales = inject(READ_BROWSER_LOCALES),
        ): GetPreferedLocales =>
        () =>
          O.getOrThrow(getBrowserLocales()),
    },
  ]);
}

/**
 * Provides a new translation scope, which allows decoupling
 * localization from the parent scope.
 *
 * The child localization can decouple from its parent in the
 * following ways:
 * - provide translations for a feature module only
 * - choose order in which languages should be shown
 *
 * @param config Configuration object
 *
 * @example
 * @NgModule({
 *   providers: [provideTranslateScope({...})]
 * })
 * class ChildModule {}
 */
export function provideTranslateScope(
  config: TranslateScopeConfig,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: TranslationService,
      useFactory: () => setupTranslationServiceForChild(config),
    },
  ]);
}

/**
 * Sets up `TranslationService` for root
 * no parent `TranslationService` is set
 */
function setupTranslationService({
  translations,
  order,
}: TranslateScopeConfig) {
  const translate = new TranslationService(null);
  translate.setTranslations(translations);
  translate.setLanguageOrder(order);

  return translate;
}

/**
 * Sets up `TranslationService` for child
 * will inject the parent `TranslationService`
 */
function setupTranslationServiceForChild({
  translations,
  order,
}: TranslateScopeConfig) {
  // The InjectFlag `Optional` is explicitly left out, because children
  // should always be initialize with a parent
  const translate = new TranslationService(
    inject(TranslationService, { skipSelf: true }),
  );
  translate.setTranslations(translations);
  translate.setLanguageOrder(order);

  return translate;
}

function _makeProviderFeature(
  kind: LocalizationFeatureKind,
  providers: Provider[],
): LocalizationFeature {
  return {
    kind,
    providers,
  };
}
