import { NgModule } from '@angular/core';
import { CardActionsComponent } from './card-actions.component';
import { CardContentComponent } from './card-content.component';
import { CardFooterComponent } from './card-footer.component';
import { CardHeaderComponent } from './card-header.component';
import {
  CardSubtitleComponent,
  CardTitleComponent,
} from './card-text.component';
import { CardComponent } from './card.component';

const declarations = [
  CardActionsComponent,
  CardContentComponent,
  CardFooterComponent,
  CardHeaderComponent,
  CardSubtitleComponent,
  CardTitleComponent,
  CardComponent,
];

/**
 * Module for cards.
 */
@NgModule({
  declarations,
  exports: declarations,
})
export class FmntsCardModule {}
