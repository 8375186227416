import { Provider } from '@angular/core';
import { AbstractFeatureFlags } from '@fmnts/common/feature-flags';
import { Customer } from '@formunauts/shared/domain';
import { SharedCustomerUiFeatureFlagsToken } from './shared-customer-ui.tokens';

/**
 * prepares providers for the shared customer ui module
 * @param opts
 */
export function provideSharedCustomerUi(opts: {
  /** Factory function to access customer feature flags. */
  featureFlags: () => AbstractFeatureFlags<Customer.Features>;
}): Provider {
  return [
    {
      provide: SharedCustomerUiFeatureFlagsToken,
      useFactory: opts.featureFlags,
    },
  ];
}
