import { NgModule } from '@angular/core';
import {
  ComplimentListComponent,
  ComplimentToggleComponent,
} from './compliment-list.component';
import { ComplimentComponent } from './compliment.component';

@NgModule({
  declarations: [
    ComplimentComponent,
    ComplimentListComponent,
    ComplimentToggleComponent,
  ],
  exports: [
    ComplimentComponent,
    ComplimentListComponent,
    ComplimentToggleComponent,
  ],
})
export class FmntsComplimentModule {}
