import { A11yModule } from '@angular/cdk/a11y';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { ModalActionsComponent } from './modal-actions.component';
import { ModalContentComponent } from './modal-content.component';
import { ModalTitleComponent } from './modal-title.component';
import { ModalContainerComponent } from './modal.component';
import { FMNTS_MODAL_SCROLL_STRATEGY_PROVIDER, Modal } from './modal.service';

const DIRECTIVES = [
  ModalContainerComponent,
  ModalTitleComponent,
  ModalActionsComponent,
  ModalContentComponent,
];

@NgModule({
  imports: [
    A11yModule,
    DialogModule,
    OverlayModule,
    PortalModule,
    ...DIRECTIVES,
  ],
  exports: [...DIRECTIVES],
  providers: [Modal, FMNTS_MODAL_SCROLL_STRATEGY_PROVIDER],
})
export class FmntsModalModule {}
