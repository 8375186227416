import { NgModule } from '@angular/core';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import {
  EmptyStateDescriptionDirective,
  EmptyStateTitleDirective,
} from './empty-state-text.directive';
import { EmptyStateVisualComponent } from './empty-state-visual.component';
import { EmptyStateComponent } from './empty-state.component';

@NgModule({
  declarations: [
    EmptyStateComponent,
    EmptyStateVisualComponent,
    EmptyStateTitleDirective,
    EmptyStateDescriptionDirective,
  ],
  exports: [
    EmptyStateComponent,
    EmptyStateVisualComponent,
    EmptyStateTitleDirective,
    EmptyStateDescriptionDirective,
  ],
  imports: [FmntsLayoutModule],
})
export class FmntsEmptyStateModule {}
