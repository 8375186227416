<div class="fmnts-image-editor-tool__main">
  <div class="fmnts-image-editor-crop-tool">
    <div
      class="fmnts-image-editor-crop-tool__box"
      [style.width]="cropWidth | async"
      [style.height]="cropHeight | async"
      [style.transform]="transformCrop() | async"
      (mousedown)="_startCropResize('box', $event)"
      (touchstart)="_startCropResize('box', $event)"
      (mouseup)="_stopCropResize()"
      (touchend)="_stopCropResize()"
    ></div>
    @for (edge of _edges; track edge) {
      <div
        tabindex="-1"
        [class.fmnts-image-editor-crop-tool__edge]="true"
        [class]="'fmnts-image-editor-crop-tool__edge--' + edge"
        [style.transform]="transformForEdge$(edge) | async"
        (mousedown)="_startCropResize(edge, $event)"
        (touchstart)="_startCropResize(edge, $event)"
        (mouseup)="_stopCropResize()"
        (touchend)="_stopCropResize()"
      ></div>
    }

    @for (corner of _corners; track corner) {
      <div
        tabindex="-1"
        [class.fmnts-image-editor-crop-tool__corner]="true"
        [class]="'fmnts-image-editor-crop-tool__corner--' + corner"
        [style.transform]="transformForCorner$(corner) | async"
        (mousedown)="_startCropResize(corner, $event)"
        (touchstart)="_startCropResize(corner, $event)"
        (mouseup)="_stopCropResize()"
        (touchend)="_stopCropResize()"
      ></div>
    }
  </div>
</div>
