import {
  inject,
  Injectable,
  OnDestroy,
  Optional,
  SkipSelf,
} from '@angular/core';
import * as O from 'effect/Option';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
} from 'rxjs';
import { READ_BROWSER_LOCALES } from './i18n.tokens';
import {
  InlineTranslateable,
  LanguageOption,
  TranslationHash,
} from './translation.types';

/**
 * @param locale Locale
 *
 * @returns
 * The language code from a locale.
 *
 * @example
 * languageFromLocale('en') // => 'en'
 * languageFromLocale('en_GB') // => 'en'
 * languageFromLocale('en-GB') // => 'en'
 */
export function languageFromLocale(locale: string): string {
  return locale.split(/[-_]/)[0];
}

@Injectable()
export class TranslationService implements OnDestroy {
  /**
   * Language used as a fallback if no other languages are available
   */
  public readonly fallbackLanguage = 'en';
  /**
   * Observable for languages to use, in the order as configured in `LocalizationModuleConfig`
   */
  public readonly languages$: BehaviorSubject<string[]>;

  /**
   * Observable for language options
   */
  public readonly languagesOptions$: BehaviorSubject<LanguageOption[]>;
  /**
   * Observable for the current language
   */
  public readonly currentLanguage$: BehaviorSubject<string>;
  /**
   * Translations that were added to the service
   */
  private readonly translationStore: TranslationHash = {};

  public order?: string[];

  /**
   * Translationservice is set up in a hierarchical way. When Translations can't
   * be found, it will check a hierarchy higher.
   *
   * The service is provided by the `l10n.module`
   * @see {@link L10nModule}
   *
   * @param _parent is either `null` if it's the root TranslationService
   * or is another TranslationService instance (e.g root TranslationService)
   */
  constructor(
    @SkipSelf()
    @Optional()
    private readonly _parent?: TranslationService | null,
  ) {
    // Set up observable for available language options
    this.languagesOptions$ = new BehaviorSubject<LanguageOption[]>([
      { source: 'default', languages: [this.fallbackLanguage] },
    ]);
    // Set up observables for languages and current language
    this.languages$ = new BehaviorSubject<string[]>([this.fallbackLanguage]);
    this.currentLanguage$ = new BehaviorSubject<string>(this.fallbackLanguage);

    this.setLanguageToBrowserLanguage();

    // No need to unsubscribe, because languages$ will be completed
    this.languages$
      .pipe(
        // Languages might be empty (e.g. when order not yet set)
        map((languages) => languages[0] ?? this.fallbackLanguage),
        distinctUntilChanged(),
      )
      .subscribe(this.currentLanguage$);
  }

  ngOnDestroy(): void {
    this.currentLanguage$.complete();
    this.languages$.complete();
  }

  /**
   * The language that can be considered primary
   */
  public get currentLanguage(): string {
    return this.currentLanguage$.getValue();
  }

  /**
   * Array of languages that is currently used for
   * translations
   */
  public get languages(): string[] {
    return this.languages$.getValue();
  }

  public setTranslations(translations: TranslationHash): void {
    Object.keys(translations).forEach((key) => {
      if (this.translationStore[key]) {
        Object.assign(this.translationStore[key], translations[key]);
      } else {
        this.translationStore[key] = translations[key];
      }
    });
  }

  public getTranslation(key: string, ...substitutions: unknown[]): string {
    for (const language of this.languages) {
      const translation = this._lookup(key, language);

      // Check if we have this translation in the requested language
      if (translation) {
        return this.substitute(translation, ...substitutions);
      }
    }

    // placeholder
    return `{{ ${key} }}`;
  }

  public getInlineTranslation(
    data: InlineTranslateable,
    ...substitutions: unknown[]
  ): string {
    if (typeof data === 'string') {
      return this.substitute(data, ...substitutions);
    }

    for (const language of this.languages) {
      let translation: string | undefined;
      if (data) {
        translation = data[language];
      }

      // Check if we have this translation in the requested language
      if (typeof translation === 'string') {
        return this.substitute(translation, ...substitutions);
      }
    }

    return JSON.stringify(data);
  }

  /**
   * @param input$ Stream that emits the source that should be translated
   * @param substitutions$ Stream that emits an array of substitutions
   *
   * @returns
   * An `Observable` that emits with the latest translated (and substituted) text
   * whenever the input, substitution or language changes.
   */
  public fromInlineTranslation$(
    input$: Observable<InlineTranslateable>,
    substitutions$: Observable<unknown[]> = of([]),
  ): Observable<string> {
    return combineLatest([input$, substitutions$, this.currentLanguage$]).pipe(
      map(([data, substitutions]) =>
        this.getInlineTranslation(data, ...substitutions),
      ),
    );
  }

  /**
   * Substitutes appearances like `${0}` in `text`, where `0` corresponds to the first substitution value
   * that was passed.
   *
   * @param text The text
   * @param substitutions The substitutions
   *
   * @returns
   * The text with the substituted values
   */
  private substitute(text: string, ...substitutions: unknown[]): string {
    return substitutions.reduce<string>(
      (acc: string, val, idx) => acc.replace(`$\{${idx + 1}\}`, val as string),
      text,
    );
  }

  /**
   * Sets a possible option for Translations.
   * This will then be ordered according to the modules localization config
   *
   * @see {@link LanguageOption}
   *
   * @example
   * Set user language:
   * ```
   * setLanguages({
   *  source: LanguageSource.User
   *  languages: ['de', 'en']
   * })
   * ```
   */
  public setLanguages(option: LanguageOption): void {
    // remove previous languages for this option
    const languageOptions = this.languagesOptions$
      .getValue()
      .filter((lang) => lang.source !== option.source);

    // Add new Language Option
    languageOptions.push(option);

    this.languagesOptions$.next(languageOptions);
    this.sortLanguages();
  }

  private setLanguageToBrowserLanguage(
    getBrowserLanguages = inject(READ_BROWSER_LOCALES),
  ): void {
    const languages = O.getOrNull(getBrowserLanguages());
    if (languages) {
      // Add all the browser languages, to the list of possible languages of the
      // user.
      const browserLanguages = languages.map(languageFromLocale);

      this.setLanguages({
        source: 'browser',
        languages: browserLanguages,
      });
    }
  }

  /**
   * Used to configure TranslationService to provide languages
   * in a configured order.
   */
  public setLanguageOrder(order: string[]): void {
    this.order = order;
    this.sortLanguages();
  }

  /**
   * Sets the languages for translations in the order that
   * they are configured in the config
   */
  private sortLanguages(): void {
    const configuredLanguages = this.languagesOptions$.getValue();
    const orderedLanguages = new Set<string>();

    if (this.order) {
      this.order.forEach((source) => {
        const lang = configuredLanguages.find(
          (language) => language.source === source,
        );

        if (lang) {
          lang.languages.forEach((language) => {
            orderedLanguages.add(language);
          });
        }
      });
    }

    this.languages$.next([...orderedLanguages]);
  }

  /**
   * Looks up a translation key for a certain language.
   * If the current instance can't find translation,
   * the parent isntance will be looked up.
   *
   * @param key Translation key
   * @param language Language identifier
   *
   * @returns
   * Translation for the given key in the given language
   */
  private _lookup(key: string, language: string): string | undefined {
    const languageStore = this.translationStore[language];
    return languageStore?.[key] ?? this._parent?._lookup(key, language);
  }
}
