import { computed, inject } from '@angular/core';
import { AuthStore } from '@formunauts/shared/auth/data-access';
import { signalStore, withComputed } from '@ngrx/signals';
import * as O from 'effect/Option';
import * as S from 'effect/Struct';

export const UserStore = signalStore(
  withComputed((store, authStore = inject(AuthStore)) => ({
    user: computed(() => O.map(authStore.userAuth(), S.get('user'))),
  })),
  withComputed((store) => ({
    username: computed(() => O.map(store.user(), S.get('username'))),
  })),
);
