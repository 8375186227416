import { NgModule } from '@angular/core';
import { LogoTypeComponent } from './logo-type.component';
import { LogoComponent } from './logo.component';
import { RocketComponent } from './rocket.component';

@NgModule({
  declarations: [LogoComponent, LogoTypeComponent, RocketComponent],
  exports: [LogoComponent, RocketComponent],
})
export class FmntsCiModule {}
