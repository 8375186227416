import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of, shareReplay, tap, zip } from 'rxjs';
import { environment } from '../environments/environment';
import { IAppEnvironment } from '../environments/environment.type';
import { ConfigService } from './core/config/config.service';
import {
  AppFeatureFlags,
  FeatureFlagInputMap,
} from './core/config/feature-flag.service';
import { Settings } from './core/config/settings';

/**
 * Holds the configuration and environment for `@fmnts/app`.
 * Provides facilities to use with `APP_INITIALIZER`.
 */
@Injectable({ providedIn: 'root' })
export class AppConfig {
  private readonly _env: Readonly<IAppEnvironment> = environment;

  /**
   * Compile-time app environment
   */
  public get env(): Readonly<IAppEnvironment> {
    return this._env;
  }

  /**
   * A multicasting observable that emits with the loaded
   * settings. Late subscribes will immediately receive
   * the value.
   */
  public readonly settings$: Observable<Settings> = iif(
    () => this._env.production,
    this.http.get<Settings>('assets/settings.json'),
    // In non-production environments we know that the settings are available
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
    of(this._env.settings!),
  ).pipe(
    tap((settings) => {
      this.configService.settings = settings;
    }),
    shareReplay(1),
  );

  /**
   * A multicasting observable that emits with the loaded
   * feature flags. Late subscribes will immediately receive
   * the value.
   */
  public readonly featureFlags$ = iif(
    () => this._env.production,
    this.http.get<FeatureFlagInputMap>('assets/feature-flags.json'),
    of<FeatureFlagInputMap>({ all: true }),
  ).pipe(
    tap((featureFlags) => {
      this.appFeatureFlags.featureFlags = featureFlags;
    }),
    shareReplay(1),
  );

  private readonly _init$ = zip(this.settings$, this.featureFlags$);

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private appFeatureFlags: AppFeatureFlags,
  ) {}

  /**
   * Use this function in the app initialization phase to
   * ensure that the app configuration is available.
   *
   * @returns
   * An `Observable` that emits when everything is initialized.
   */
  public init$(): Observable<any> {
    return this._init$;
  }
}
