import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { SidebarContainerComponent } from './sidebar-container.component';
import { SidebarItemComponent } from './sidebar-item.component';
import { SidebarSectionComponent } from './sidebar-section.component';

@NgModule({
  imports: [FmntsIconsModule],
  exports: [
    SidebarContainerComponent,
    SidebarItemComponent,
    SidebarSectionComponent,
  ],
  declarations: [
    SidebarContainerComponent,
    SidebarItemComponent,
    SidebarSectionComponent,
  ],
})
export class FmntsSidebarModule {}
