import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { selectAssignedCustomerInitialized } from './+state/customer.selectors';

/**
 * Guard to make sure that the assigned customer was initialized
 */
@Injectable({ providedIn: 'root' })
export class AwaitCustomerInitializedGuard {
  private readonly store = inject(Store);

  private isCustomerInitialized$ = this.store.select(
    selectAssignedCustomerInitialized,
  );

  canActivate(): Observable<boolean> {
    return this.isCustomerInitialized$.pipe(
      filter((initialized) => initialized),
    );
  }
}
