import * as Match from 'effect/Match';
import * as Num from 'effect/Number';
import * as O from 'effect/Option';
import * as P from 'effect/Predicate';
import {
  EntityNotFoundError,
  GetEntityError,
  InfrastructureError,
  ServerUnavailableError,
  UnexpectedServerError,
} from './common-error.model';
import { GeneralHttpError } from './general-http-error';

/**
 * @returns
 * true, if the status code is a server error status code.
 */
export const isServerErrorStatusCode = P.and(
  Num.greaterThanOrEqualTo(500),
  Num.lessThan(600),
);

/**
 * Catches infrastructure errors.
 */
export function getInfrastructureError(
  e: GeneralHttpError,
): O.Option<InfrastructureError> {
  return Match.value(e.status).pipe(
    Match.when(503, () => O.some(new ServerUnavailableError())),
    Match.when(isServerErrorStatusCode, () =>
      O.some(new UnexpectedServerError()),
    ),
    Match.orElse(() => O.none()),
  );
}

/**
 * Catches errors that occur when trying to get an entity.
 */
export function catchGetEntityError(
  e: GeneralHttpError,
): O.Option<GetEntityError<EntityNotFoundError>> {
  return Match.value(e.status).pipe(
    Match.when(404, () => O.some(new EntityNotFoundError())),
    Match.orElse(() => getInfrastructureError(e)),
  );
}
