import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import { I18nModule } from '@fmnts/i18n';
import { ToastContainerComponent } from './toast-container';
import { ToastContentComponent } from './toast-content.component';
import { ToastGroupComponent } from './toast-group.component';
import { ToastTextComponent } from './toast-text.component';
import { ToastTitleComponent } from './toast-title.component';
import { ToastComponent } from './toast.component';
import { Toaster } from './toaster';

@NgModule({
  imports: [
    OverlayModule,
    PortalModule,
    FmntsLayoutModule,
    I18nModule,
    FmntsIconsModule,
    FmntsButtonModule,
    ToastContentComponent,
    ToastTitleComponent,
    ToastTextComponent,
  ],
  exports: [
    ToastComponent,
    ToastContainerComponent,
    ToastGroupComponent,
    ToastTitleComponent,
    ToastTextComponent,
    ToastContentComponent,
  ],
  declarations: [ToastComponent, ToastContainerComponent, ToastGroupComponent],
  providers: [Toaster],
})
export class FmntsToastModule {}
