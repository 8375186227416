<!-- Editor -->
<div
  class="fmnts-image-editor__root"
  [class.fmnts-image-editor__root--preview]="!isEditing"
>
  <!-- Utils General (Undo, Done)-->
  <div
    class="fmnts-image-editor-utils fmnts-image-editor-utils--general"
    [class.fmnts-image-editor-utils--hidden]="!isEditing || !currentUtil"
  >
    <ng-content select="[fmnts-image-editor-general]" />
  </div>

  <!-- Canvas-->
  <div
    class="fmnts-image-editor__canvas"
    [class.fmnts-image-editor__canvas--preview]="!isEditing"
  >
    <img
      #originalImage
      class="fmnts-image-editor__image"
      [src]="(image$ | async)?.dataUri ?? null"
      [style.transform]="imageTransform$ | async"
      (load)="onImageLoaded(originalImage)"
    />

    <!-- This canvas is used to render the final image -->
    <canvas
      #canvas
      [style.position]="'absolute'"
      [style.display]="'none'"
      [height]="_canvasHeight$ | async"
      [width]="_canvasWidth$ | async"
    ></canvas>
  </div>

  <!-- Utils Main Panel -->
  <div
    class="fmnts-image-editor-utils fmnts-image-editor-utils--main"
    [style.width.px]="_viewportWidth$ | async"
    [style.height.px]="_viewportHeight$ | async"
  >
    <div
      class="fmnts-image-editor-utils__panel-wrapper"
      [class.fmnts-image-editor-utils__panel-wrapper--hidden]="!isEditing"
    >
      @switch (currentUtil) {
        @case ('crop') {
          <ng-content select="fmnts-image-editor-crop-tool" />
        }
      }
    </div>
  </div>
</div>
