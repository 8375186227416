import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { ExpansionPanelHeaderComponent } from './expansion-panel-header.component';
import { ExpansionPanelComponent } from './expansion-panel.component';

@NgModule({
  declarations: [ExpansionPanelComponent, ExpansionPanelHeaderComponent],
  exports: [ExpansionPanelComponent, ExpansionPanelHeaderComponent],
  imports: [FmntsIconsModule],
})
export class FmntsExpansionModule {}
