<div class="fmnts-icon-rating__icons">
  @for (s of _maxItem; track s) {
    <fa-icon
      class="fmnts-icon-rating__icon"
      [class.fmnts-icon-rating__icon--readonly]="readonly"
      [icon]="icon"
      [size]="iconSize"
      aria-hidden="true"
      (click)="toggleRating(s)"
      (mouseover)="hoverRating(s)"
      (mouseleave)="unhoverRating()"
    />
  }
</div>

<div
  class="fmnts-icon-rating__icons--filled"
  [style.width.%]="(_filledStars / max) * 100"
>
  @for (s of _maxItem; track s) {
    <fa-icon
      class="fmnts-icon-rating__icon fmnts-icon-rating__icon--filled"
      [class.fmnts-icon-rating__icon--readonly]="readonly"
      [icon]="icon"
      [size]="iconSize"
      aria-hidden="true"
      (click)="toggleRating(s)"
      (mouseover)="hoverRating(s)"
      (mouseleave)="unhoverRating()"
    />
  }
</div>
