import { AsyncPipe, NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsCommonModule } from '@fmnts/common';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FluidImageComponent } from './fluid-image.component';
import { ImageEditorCropToolComponent } from './image-editor-crop-tool.component';
import { ImageEditorComponent } from './image-editor.component';
import { ImageInputComponent } from './image-input.component';
import { ScaleImageComponent } from './scale-image.component';

@NgModule({
  declarations: [
    FluidImageComponent,
    ImageInputComponent,
    ImageEditorComponent,
    ScaleImageComponent,
    ImageEditorCropToolComponent,
  ],
  exports: [
    FluidImageComponent,
    ImageInputComponent,
    ImageEditorComponent,
    ScaleImageComponent,
    ImageEditorCropToolComponent,
  ],
  imports: [
    AsyncPipe,
    NgClass,
    FmntsButtonModule,
    FmntsIconsModule,
    FmntsCommonModule,
  ],
})
export class FmntsImageModule {}
