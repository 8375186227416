@if (showTotals) {
  <div class="pagination__totals">
    <span
      [innerHTML]="
        'pagination_result'
          | translate: resultsStart() : resultsEnd() : _count()
      "
    ></span>
  </div>
}
<div class="extra_pagination_action">
  <ng-content />
</div>
<ul class="pages">
  <button
    fmnts-icon-button
    class="pagination--previous-page"
    type="button"
    variant="plain"
    [disabled]="prevButtonDisabled()"
    action="normal"
    size="sm"
    (click)="navigateTo(previousPage())"
  >
    <fa-icon [icon]="_iconPrev" />
  </button>
  @for (pageRange of visibleRanges(); track $index) {
    @for (page of pageRange; track page) {
      <button
        fmnts-icon-button
        type="button"
        [class.pagination--active-page]="currentPage === page"
        action="normal"
        variant="plain"
        size="sm"
        class="page"
        (click)="navigateTo(page)"
      >
        {{ page }}
      </button>
    }
    @if ($index !== visibleRanges().length - 1) {
      <span class="w-8 inline-block text-center">...</span>
    }
  }
  <button
    fmnts-icon-button
    class="pagination--next-page"
    type="button"
    variant="plain"
    action="normal"
    size="sm"
    [disabled]="nextButtonDisabled()"
    (click)="navigateTo(nextPage())"
  >
    <fa-icon [icon]="_iconNext" />
  </button>
</ul>
