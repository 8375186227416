import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OldFrontendLocationStorageService } from '@app/shared/old-frontend/data-access';
import { BrowserCaches } from '@fmnts/common/browser';
import { LegacyStore } from '@formunauts/next/data-access';
import {
  AuthApiActions,
  AuthInterceptorActions,
} from '@formunauts/shared/auth/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs';

@Injectable()
export class UserSessionEffects {
  requestUnauthenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthInterceptorActions.unauthorizedRequested),
      map(() => AuthApiActions.unauthenticate.request({})),
    );
  });

  redirectToLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
        ),
        tap(() => {
          // TODO: Cockpit styling should not affect other styling
          // Once the styles are scoped correctly, use navigation
          // this.router.navigateByUrl('/');
          window.location.href = '/';
        }),
      );
    },
    { dispatch: false },
  );

  clearLegacyStore$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.authenticate.success,
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
        ),
        tap(() => {
          this.legacyStore.clear();
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Cleans up user data once the user is logged out.
   */
  cleanupPersistedData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
        ),
        tap(() => {
          this.ofLocationStorage.clearStoredLocation();
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect to cleanup cached responses.
   */
  cleanupCachedResponses$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
        ),
        switchMap(() =>
          // keep in sync with ngws config
          this.browserCaches.clearCacheStorage([
            /(session_cache)/,
            /(stale_while_revalidate)/,
            /(fallback_api)/,
          ]),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private legacyStore: LegacyStore,
    private browserCaches: BrowserCaches,
    private ofLocationStorage: OldFrontendLocationStorageService,
  ) {}
}
