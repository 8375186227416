import { TrashItem } from '@formunauts/shared/diagnostic/domain';
import { type Observable } from 'rxjs';

/**
 * Token for the form data trash repository.
 */
export abstract class FormDataTrashRepository {
  /**
   * Persists the `entity`.
   *
   * @param dto Entity to persist.
   */
  abstract put<TContent>(
    dto: TrashItem.AddTrashedFormDataDto<TContent>,
  ): Observable<TrashItem.TrashedFormData<TContent>>;

  /**
   * @returns
   * An Observable that emits the entities.
   */
  abstract getAll(): Observable<TrashItem.TrashedFormData<unknown>[]>;

  /**
   * Removes the entities with the given IDs.
   *
   * @param ids IDs of entities to be deleted.
   */
  abstract removeByIds(ids: TrashItem.TrashItemId[]): Observable<number>;

  /**
   * Expires entities.
   *
   * @returns
   * An Observable that emits with the number of expired entities^.
   */
  abstract expireEntries(): Observable<number>;
}
