import { createHttpActions } from '@fmnts/common/store';
import { Customer } from '@formunauts/shared/domain';
import { GeneralHttpError } from '@formunauts/shared/errors/domain';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

enum CustomerActionSource {
  Api = 'Customer API',
  Root = 'Customer Root',
}

export const CustomerRootActions = createActionGroup({
  source: CustomerActionSource.Root,
  events: {
    'Set Assigned Customer': props<{
      customerId: Customer.CustomerId | null;
    }>(),
    'Unset Assigned Customer': emptyProps(),
  },
});

export const CustomerApiGetActions = createHttpActions(
  CustomerActionSource.Api,
  'Get Customer',
  {
    request: props<{ customerId: Customer.CustomerId }>(),
    success: props<{ payload: Customer.Customer }>(),
    failure: props<{ error: GeneralHttpError }>(),
  },
);

export const CustomerApiActions = {
  getCustomer: CustomerApiGetActions,
};
