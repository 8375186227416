import { SupportRequest } from '@formunauts/shared/support/domain';
import { Observable } from 'rxjs';
import {
  SupportRequestAddDto,
  SupportRequestScreenshotChangeDto,
} from './support-request.entity';

/**
 * DI token for accessing the support request repository.
 */
export abstract class SupportRequestRepository<
  TContext extends SupportRequest.RequestContext,
> {
  /**
   * Adds a new support request.
   */
  abstract add(
    dto: SupportRequestAddDto<TContext>,
  ): Observable<SupportRequest.SupportRequest<TContext>>;

  /**
   * Upserts a screenshot to the support request.
   *
   * @param entity Support request to attach the screenshot to.
   * @param changes Changes
   * @returns
   * An Observable that emits with the URL of the screenshot, then completes.
   */
  abstract upsertScreenshot(
    entity: SupportRequest.SupportRequest<TContext>,
    changes: SupportRequestScreenshotChangeDto,
  ): Observable<SupportRequest.SupportRequest<TContext>>;
}
