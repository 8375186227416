import { inject, Injectable } from '@angular/core';
import { logger } from '@fmnts/common/log';
import { RingBuffer } from '@fmnts/core/collection';
import { TrashItem } from '@formunauts/shared/diagnostic/domain';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { produce } from 'immer';
import { concatMap, debounceTime, exhaustMap, merge, Observable } from 'rxjs';
import { FormDataTrashRepository } from './form-data-trash.repository';

export abstract class FormDataTrashService {
  /**
   * Entities in the trash.
   */
  abstract getAll(): Observable<TrashItem.TrashedFormData<unknown>[]>;

  /**
   * Puts an item in the form data trash.
   *
   * @param dto Item to put in the trash
   */
  abstract put(dto: TrashItem.AddTrashedFormDataDto<unknown>): void;
}

interface TrashState {
  entities: TrashItem.TrashedFormData<unknown>[];
}

/**
 * Configuration for trash.
 */
export abstract class FormDataTrashConfig {
  abstract maxEntries: number;
}

@Injectable()
export class FormDataTrashImpl
  extends ComponentStore<TrashState>
  implements FormDataTrashService
{
  private readonly _config = inject(FormDataTrashConfig);
  private readonly _repo = inject(FormDataTrashRepository);
  private readonly _log = logger({
    name: FormDataTrashImpl.name,
    category: 'indexeddb',
  });

  private readonly entities$ = this.select(({ entities }) => entities);

  constructor() {
    super({ entities: [] });
    this._addItems(this._repo.getAll());
  }

  /**
   * Puts the given `item` in the trash.
   *
   * @param item Item
   * @returns
   * A promise that resolves once them item was
   * persisted in the trash.
   */
  private readonly _put = this.effect<TrashItem.AddTrashedFormDataDto<unknown>>(
    (dto$) =>
      dto$.pipe(
        concatMap((dto) =>
          this._repo.put(dto).pipe(
            tapResponse(
              (entity) => this._addItems([entity]),
              (error) => {
                this._log.error('Item could not be persisted', { dto, error });
              },
            ),
          ),
        ),
      ),
  );

  /**
   * Removes expired entries from the persistance implementation,
   * whenever data changed or when explicitly triggered.
   */
  protected readonly _expireEntries = this.effect<void>((trigger$) =>
    merge(trigger$, this.entities$.pipe(debounceTime(2000))).pipe(
      exhaustMap(() => this._repo.expireEntries()),
    ),
  );

  /** Adds the given items to the entries. */
  private readonly _addItems = this.updater(
    produce<TrashState, [TrashItem.TrashedFormData<unknown>[]]>(
      (draft, items) => {
        const buf = new RingBuffer<TrashItem.TrashedFormData<unknown>>(
          this._config.maxEntries,
        );

        for (const item of [...draft.entities, ...items]) {
          buf.push(item);
        }

        draft.entities = [...buf];
      },
    ),
  );

  getAll(): Observable<TrashItem.TrashedFormData<unknown>[]> {
    return this.entities$;
  }

  put(dto: TrashItem.AddTrashedFormDataDto<unknown>): void {
    this._put(dto);
  }
}
