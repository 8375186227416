import { inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageItemAccessor } from '@fmnts/common/storage';
import { JsonSerializer } from '@fmnts/core';
import { distinctUntilChanged, Observable, pluck } from 'rxjs';

interface State {
  sessionValues: any;
  globalValues: any;
  [key: string]: any;
}

const DEFAULT_STATE: State = {
  sessionValues: {},
  globalValues: {},
};

const STATE_STORAGE_KEY = 'formunauts-state';

/**
 * Legacy store technology.
 *
 * @deprecated
 * Do not use in new code. Use ngrx Store instead.
 */
@Injectable({ providedIn: 'root' })
export class LegacyStore extends StorageItemAccessor<State> {
  constructor() {
    super(
      inject(LOCAL_STORAGE),
      STATE_STORAGE_KEY,
      DEFAULT_STATE,
      new JsonSerializer(),
    );
  }

  /**
   * Watch changes to a value in store. Allows watching nested values using
   * dot syntax (e.g. car.brand.name for `{ car: { brand: { name: 'any' } } }`).
   *
   * @param key The key to access
   */
  public select<T>(key: string): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - actual type can't be inferred from key path.
    // eslint-disable-next-line deprecation/deprecation
    return this.store.pipe(pluck(...key.split('.')), distinctUntilChanged<T>());
  }

  //
  public clear(includeGlobalValues = false): void {
    if (includeGlobalValues) {
      this.reset();
    } else {
      this.reset({
        globalValues: this.value.globalValues,
      });
    }
  }
}
