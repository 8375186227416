import { NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsFormFieldModule } from '@fmnts/components/form-field';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import {
  FmntsInputPrefixDirective,
  FmntsInputSuffixDirective,
} from './input-prefix-suffix.directive';
import { FmntsInputDirective } from './input.directive';
import { FmntsTextFieldComponent } from './text-input.component';

@NgModule({
  declarations: [
    FmntsInputDirective,
    FmntsInputPrefixDirective,
    FmntsInputSuffixDirective,
    FmntsTextFieldComponent,
  ],
  exports: [
    FmntsInputDirective,
    FmntsInputPrefixDirective,
    FmntsInputSuffixDirective,
    FmntsTextFieldComponent,
    FmntsFormFieldModule,
  ],
  imports: [
    FmntsFormFieldModule,
    FmntsIconsModule,
    FmntsLayoutModule,
    NgTemplateOutlet,
  ],
})
export class FmntsInputModule {}
