import { Injectable } from '@angular/core';
import { OfflineStoredData, UploadData } from '@fmnts/common/store';

/**
 * This is the key that is used in the legacy frontend.
 */
export const LEGACY_OFFLINE_STORAGE_KEY = 'ngStorage-formData';

export type LegacyOfflineStoreType =
  | 'donation'
  | 'petition'
  | 'recruiting'
  | 'timecapture'
  | 'locationselect';

/**
 * Interface for Offline-stored Datasets
 * Extends OfflineStoredData by `id` and `category`
 */
export interface LegacyOfflineStored<T = unknown> extends OfflineStoredData<T> {
  id: string | number;
  category: LegacyOfflineStoreType;
}
/**
 * RegExp for matching instances of an offline-stored dataset
 * e.g. `123_donation__1847914981` or `1234_locationselect__438758317`
 */
export const expressionRegex = /(\d*)\_(\w*)\__(.*)/;

/**
 * Service for bridging stored form data for the old frontend.
 */
@Injectable({
  providedIn: 'root',
})
export class OldFrontendFormDataStorageService {
  constructor() {}

  /**
   * Generates a unique-ish key to store a certain form data set in the local storage.
   *
   * @param id ID prefix for the item
   * @param type Type of the data set
   * @param timestamp Timestamp of when the data set was created
   *
   * @returns
   * Key to store the data set
   */
  public createKey(
    id: string | number,
    type: LegacyOfflineStoreType,
    timestamp: Date | string,
  ): string {
    if (timestamp instanceof Date) {
      return `${id}_${type}__${timestamp.getTime()}`;
    } else {
      return `${id}_${type}__${timestamp}`;
    }
  }

  /**
   *
   * @param storageKey
   * @returns
   */
  public parseKey(
    storageKey: string,
  ): Pick<LegacyOfflineStored, 'id' | 'category' | 'timestamp'> {
    const match = expressionRegex.exec(storageKey);
    if (!match) {
      throw new Error('Could not parse offline stored dataset');
    }
    const [_, id, category, timestamp] = match;

    return {
      id,
      category: category as LegacyOfflineStoreType,
      timestamp,
    };
  }
}

export function offlineStoreReference<T>(
  payload: UploadData<LegacyOfflineStored<T>>,
): string {
  return `${payload.id}_${payload.category}__${payload.timestamp}`;
}
