<div class="calendar-header">
  <div class="current-month">
    {{ displayMonth$ | async | amDateFormat: 'MMMM YYYY' }}
  </div>

  <div class="controls">
    <button
      fmnts-icon-button
      type="button"
      color="primary"
      fill="solid"
      size="sm"
      [disabled]="!canGoToPreviousMonth()"
      [class.calendar-picker__button--inactive]="!canGoToPreviousMonth()"
      (click)="goToPreviousMonth()"
    >
      <fa-icon [icon]="iconPrev" [fixedWidth]="true" />
    </button>

    <button
      fmnts-icon-button
      type="button"
      color="primary"
      fill="solid"
      size="sm"
      [disabled]="!canGoToNextMonth()"
      [class.calendar-picker__button--inactive]="!canGoToNextMonth()"
      (click)="goToNextMonth()"
    >
      <fa-icon [icon]="iconNext" [fixedWidth]="true" />
    </button>
  </div>
</div>

<div class="calendar" [class.range-select]="range">
  <div class="weekday-header">
    <div class="calendar-week">
      {{ 'calendar_week_abbreviation' | translate }}
    </div>
    <div class="weekdays">
      @for (weekday of weekdays$ | async; track weekday) {
        <div class="weekday">
          {{ weekday | amDateFormat: 'dd' }}
        </div>
      }
    </div>
  </div>

  <div class="dates">
    @for (calendarWeek of displayCalendarWeeks$ | async; track calendarWeek) {
      <div class="weeks">
        <div
          class="week"
          (click)="selectWeek(calendarWeek.days[0])"
          (mouseenter)="highlightedWeek = calendarWeek.weekNumber"
          (mouseleave)="highlightedWeek = null"
        >
          {{ calendarWeek.weekNumber }}
        </div>
        <div
          class="days"
          [class.week-highlighted]="highlightedWeek === calendarWeek.weekNumber"
        >
          @for (day of calendarWeek.days; track day) {
            <div
              class="day"
              [class.calendar-picker__day--disabled]="!canSelectDay(day)"
              [class.selected]="isInSelectedDateRange(day)"
              [class.preview]="isInPreviewDateRange(day)"
              [class.start-range]="isStartDate(day)"
              [class.in-range]="isInRange(day)"
              [class.end-range]="isEndDate(day)"
              [class.different-month]="!isInSameMonth(day)"
              (mouseenter)="updateProposedDates(day, $event)"
              (click)="selectDate(day)"
            >
              {{ day | amDateFormat: 'DD' }}
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
