import { InjectionToken } from '@angular/core';
import { SupportRequest } from '@formunauts/shared/support/domain';

/**
 * Injection token to resolve the defaults for the support request user data.
 */
export const SUPPORT_REQUEST_USER_DATA_RESOLVE_FN = new InjectionToken<
  () => Partial<SupportRequest.SupportRequestUserData>
>('shared.support.request-user-data.resolve-fn', {
  factory: () => () => ({}),
});
