import { NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsFormErrorDirective } from './form-error.directive';
import { FmntsFormFieldComponent } from './form-field.component';
import { FmntsHintDirective } from './hint.directive';
import { FmntsLabelDirective } from './label.directive';

@NgModule({
  declarations: [
    FmntsFormErrorDirective,
    FmntsFormFieldComponent,
    FmntsHintDirective,
    FmntsLabelDirective,
  ],
  exports: [
    FmntsFormErrorDirective,
    FmntsFormFieldComponent,
    FmntsHintDirective,
    FmntsLabelDirective,
  ],
  imports: [FmntsIconsModule, NgTemplateOutlet],
})
export class FmntsFormFieldModule {}
