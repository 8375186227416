import { Location } from '@angular/common';
import { Provider, inject } from '@angular/core';
import { LOCAL_STORAGE } from '@fmnts/common/storage';
import { Modal } from '@fmnts/components/modal';
import { MaybeOption, Option } from '@fmnts/core';
import { selectAuth } from '@formunauts/shared/auth/data-access';
import { Bundle } from '@formunauts/shared/bundle/data-access';
import { bundleShortVersionString } from '@formunauts/shared/bundle/domain';
import {
  provideSharedSupportDataAccess,
  withAdditionalContext,
  withDefaultsForSupportRequestForm,
  withSupportRequestDialog,
} from '@formunauts/shared/support/data-access';
import { SupportRequestModalComponent } from '@formunauts/shared/support/feature';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import {
  AppSupportRequestContext,
  AppSupportRequestRepository,
} from './app-support-request.repository';

/**
 * Provides the support request feature for the app.
 */
export function provideAppSupportRequest(): Provider {
  return [
    provideSharedSupportDataAccess(
      {
        repository: () => inject(AppSupportRequestRepository),
      },
      withAdditionalContext<AppSupportRequestContext>(
        (
          location = inject(Location),
          localStorage = inject(LOCAL_STORAGE),
          store = inject(Store),
          bundle = inject(Bundle),
        ) => ({
          path: () => location.path(),
          version: () => bundleShortVersionString(bundle.version),
          // TODO: implement collection of context
          logs: () => null,
          local_storage: () => ({ ...localStorage }),
          // TODO: implement collection of context
          sentry: () => null,
          state: () => {
            let ngrxState: Option<Record<string, unknown>> = null;
            store
              .select((s) => s)
              .pipe(first())
              .subscribe(
                (state) =>
                  (ngrxState = state as unknown as Record<string, unknown>),
              );
            return ngrxState;
          },
          // TODO: implement collection of context
          system: () => null,
          // TODO: implement collection of context
          data_trash: () => null,
        }),
      ),
      withSupportRequestDialog({
        dialog: (modal = inject(Modal)) => ({
          open: () =>
            modal.open(SupportRequestModalComponent, {
              disableClose: true,
            }),
        }),
      }),
      withDefaultsForSupportRequestForm({
        userData: () => {
          let email: MaybeOption<string>;

          inject(Store)
            .select(selectAuth)
            .pipe(first())
            .subscribe((auth) => {
              if (auth) {
                email = auth.email;
              }
            });

          return {
            email,
          };
        },
      }),
    ),
  ];
}
