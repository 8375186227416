import { Either, matchEither } from './either';
import {
  isDefined,
  isNotNull,
  isNotNullish,
  isNull,
  isUndefined,
} from './type-guards/primitive.type-guard';

export const none = null;
export const maybe = undefined;
type NONE = typeof none;
type MAYBE = typeof maybe;

/**
 * Represents a value that is either set or has no value.
 *
 * @example
 * // No refresh yet, so its none
 * const lastRefresh: Option<Date> = none;
 */
export type Option<T> = Either<NONE, T>;
/**
 * Represents a value that is either given or unknown.
 *
 * @example
 * // Not yet fetched, so its maybe
 * const user: Maybe<User> = maybe;
 */
export type Maybe<T> = Either<MAYBE, T>;
/**
 * Represents a value that is either.
 * - unknown
 * - not set
 * - set
 *
 * It's a conventient combination of Option and Maybe.
 *
 * @example
 * interface Something {
 *   // Something can have an optional owner or the owner is unknown.
 *   owner: MaybeOption<User>;
 * }
 */
export type MaybeOption<T> = Maybe<Option<T>>;

/** Type guard to ensure `Option<T>` is `T`. */
export const optionHasValue = isNotNull;
/** Type guard to ensure `Maybe<T>` is `T`. */
export const maybeHasValue = isDefined;
/** Type guard to ensure `MaybeOption<T>` is `T`. */
export const maybeOptionHasValue = isNotNullish;

/**
 * Creates a function to transform an `Option`s value.
 */
export function matchOption<T, A>(opts: {
  onSome: (value: T) => A;
}): (value: Option<T>) => Option<A>;
/**
 * Creates a function to transform an `Option`s value.
 */
export function matchOption<T, A, B>(opts: {
  onSome: (value: T) => A;
  onNone?: () => B;
}): (value: Option<T>) => A | B;
export function matchOption<T>({
  onSome,
  onNone = () => none,
}: {
  onSome: (value: T) => unknown;
  onNone?: () => unknown;
}): (value: Option<T>) => unknown {
  return matchEither(isNull, {
    onLeft: onNone,
    onRight: onSome,
  });
}

/**
 * Creates a function to transform a `Maybe`s value.
 */
export function matchMaybe<T, A>(opts: {
  onValue: (value: T) => A;
}): (value: Maybe<T>) => Maybe<A>;
/**
 * Creates a function to transform a `Maybe`s value.
 */
export function matchMaybe<T, A, B>(opts: {
  onValue: (value: T) => A;
  onMaybe?: () => B;
}): (value: Maybe<T>) => A | B;
export function matchMaybe<T>({
  onValue,
  onMaybe = () => maybe,
}: {
  onValue: (value: T) => unknown;
  onMaybe?: () => unknown;
}): (value: Maybe<T>) => unknown {
  return matchEither(isUndefined, {
    onLeft: onMaybe,
    onRight: onValue,
  });
}

/**
 * Creates a function to transform a `MaybeOption`s value.
 */
export function matchMaybeOption<T, R>(options: {
  onValue: (value: T) => R;
}): (value: MaybeOption<T>) => MaybeOption<R>;
/**
 * Creates a function to transform a `MaybeOption`s value.
 */
export function matchMaybeOption<T, R, WithNone, WithMaybe>(options: {
  onValue: (value: T) => R;
  onMaybe?: () => WithMaybe;
  onNone?: () => WithNone;
}): (value: MaybeOption<T>) => R | WithMaybe | WithNone;
export function matchMaybeOption({
  onValue,
  onMaybe,
  onNone,
}: {
  onValue: (value: unknown) => unknown;
  onMaybe?: () => unknown;
  onNone?: () => unknown;
}): (value: MaybeOption<unknown>) => unknown {
  return matchMaybe({
    onMaybe,
    onValue: matchOption({
      onSome: onValue,
      onNone,
    }),
  });
}
